@import "../../common.scss";

.card {
  border: 0.5px solid transparent;
  border-radius: 5px;
  padding: 18px;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid $secondary-text-color;
  &:hover {
    box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
  }
  .card-title {
    border-bottom: 1px solid transparent;
    padding-bottom: 10px;
  }
  .card-content {
    padding-top: 10px;
    display: flex;
    .image-item {
      // opacity: 0.5;
      .cropped {
        width: 100%; /* width of container */
        height: auto; /* height of container */
        border-radius: 5px;
        filter: grayscale(100%);
      }
      @media screen and (max-width: 768px) {
        display: none !important;
      }
      &:hover {
        opacity: 1;
      }
    }
    .text-item {
      margin-left: 20px;
    }
  }
}
