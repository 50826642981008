@import "../../common.scss";

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $secondary-color;
  color: $secondary-text-color;
  text-align: center;
  z-index: 10;
}
