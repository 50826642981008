@import "../../common.scss";

.header {
  position: fixed; /* Make the header fixed */
  top: 0; /* Stick to the top of the page */
  left: 0; /* Ensure it starts from the left */
  width: 100%; /* Full width of the viewport */
  background: $primary-color;
  z-index: 1000; /* Ensure it's above other content */
  padding: 10px 0; /* Add padding as needed */
}

.sub-header {
  position: fixed; /* Make the sub-header fixed */
  top: 108px; /* Position it just below the header; adjust this value to match the height of the header */
  left: 0; /* Ensure it starts from the left */
  width: 100%;
  background: $secondary-color;
  z-index: 999; /* Slightly lower than the header but still above the content */
  padding: 5px 0; /* Add padding as needed */
}

.menu-item {
  display: inline-flex;
  width: 23%;
  .menu-text {
    margin: auto;
    padding: 10px;
    color: $secondary-text-color;
    &:hover {
      box-shadow: 0px 15px 21px -10px $primary-color;
    }
  }
  .icon-right-padding {
    margin-right: 10px;
  }
}

.title {
  color: $secondary-text-color;
  display: inline-flex;
  font-family: "Pacifico", cursive;
  font-size: 50px;
  margin-left: 2%;
}

.sub-title {
  color: $secondary-text-color;
  display: inline-flex;
  font-family: "Pacifico", cursive;
  font-size: 20px;
  margin-left: 2%;
}

.margin-right-5px {
  margin-right: 5px;
}

.link-color {
  text-decoration: none;
  color: $secondary-text-color;
}

.visited {
  box-shadow: 0px 15px 21px -10px $primary-color;
}
