/* App.css */
body, html {
    height: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f6f6f6;
  }
  
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
  }
  
  .decorative-elements {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .circle {
    position: absolute;
    border-radius: 50%;
    opacity: 0.8;
  }
  
  .green {
    background-color: #4CAF50;
    width: 200px;
    height: 200px;
    top: 10%;
    left: 10%;
  }
  
  .yellow {
    background-color: #FFEB3B;
    width: 150px;
    height: 150px;
    top: 70%;
    left: 80%;
  }
  
  .orange {
    background-color: #FF9800;
    width: 100px;
    height: 100px;
    top: 30%;
    left: 60%;
  }
  
  .semi-circle {
    background-color: #9E9E9E;
    width: 150px;
    height: 75px;
    border-top-left-radius: 75px;
    border-top-right-radius: 75px;
    top: 50%;
    left: 20%;
  }
  
  .login-form {
    z-index: 1;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .logo img {
    width: 50px;
    height: 50px;
  }
  
  h2 {
    margin-top: 10px;
    font-size: 24px;
  }
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background-color: #FFD700;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #FFC107;
  }
  
  .forgot-password {
    display: block;
    margin-top: 10px;
    color: #007BFF;
    text-decoration: none;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  