/* SemicolonWord.css */

@import url("https://fonts.googleapis.com/css2?family=Anton&family=Concert+One&display=swap");
@import "../../common.scss"; /* Import common styles */

.semicolon-container {
  display: flex; /* Use flexbox to align items horizontally */
  flex-wrap: wrap; /* Allow items to wrap to new lines on smaller screens */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  gap: 1vw; /* Space between each letter, responsive using vw units */
  margin: 10px; /* Add some margin around the container */
}

.letter-circle {
  background-color: $color-three; /* Fallback color if SVG fails */
  background-size: 20px 20px; /* Adjust the size of the pattern */
  width: 10vw; /* Responsive width using viewport width units */
  height: 10vw; /* Responsive height using viewport width units */
  max-width: 110px; /* Maximum width to prevent it from getting too large */
  max-height: 110px; /* Maximum height to prevent it from getting too large */
  min-width: 50px; /* Minimum width to ensure it's not too small */
  min-height: 50px; /* Minimum height to ensure it's not too small */
  border-radius: 25%; /* Border radius for rounded shape */
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-size: 3vw; /* Responsive font size using viewport width units */
  color: #333; /* Text color */
  font-weight: bold; /* Make text bold */
  flex-shrink: 0; /* Prevent the items from shrinking */
  transition: all 0.3s ease; /* Smooth transition for resizing */
  font-family: "Concert One", sans-serif;
  animation: morphing-shape 5s infinite ease-in-out; /* Apply the animation */
}

.letter-circle-semicolon {
  position: relative; 
  background-color: $secondary-color; /* Fallback color if SVG fails */
  background-size: 20px 20px; /* Adjust the size of the pattern */
  width: 10vw; /* Responsive width using viewport width units */
  height: 10vw; /* Responsive height using viewport width units */
  max-width: 110px; /* Maximum width to prevent it from getting too large */
  max-height: 110px; /* Maximum height to prevent it from getting too large */
  min-width: 50px; /* Minimum width to ensure it's not too small */
  min-height: 50px; /* Minimum height to ensure it's not too small */
  border-radius: 25%; /* Border radius for rounded shape */
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-size: 3vw; /* Responsive font size using viewport width units */
  color: #333; /* Text color */
  font-weight: bold; /* Make text bold */
  flex-shrink: 0; /* Prevent the items from shrinking */
  transition: all 0.3s ease, opacity 0.5s ease-out; /* Combine transitions */
  font-family: "Concert One", sans-serif;
  cursor: pointer;
}

@keyframes morphing-shape {
  0% {
    border-radius: 50%; /* Circle shape */
  }
  25% {
    border-radius: 40% 60% 60% 40% / 50% 40% 60% 50%; /* Elliptical shape */
  }
  50% {
    border-radius: 30% 70% 50% 50% / 60% 40% 60% 40%; /* Irregular shape */
  }
  75% {
    border-radius: 60% 40% 40% 60% / 40% 60% 40% 60%; /* Another irregular shape */
  }
  100% {
    border-radius: 50%; /* Back to the circle shape */
  }
}

/* Additional media query for very small screens */
@media (max-width: 600px) {
  .letter-circle {
    width: 12vw; /* Slightly larger size on small screens */
    height: 12vw;
    font-size: 4vw; /* Adjust font size for small screens */
  }
}

.drip {
  animation: dripToFloor 2s ease-in forwards; /* Trigger drip animation */
}

/* Keyframes for the dripping effect */
@keyframes dripToFloor {
  0% {
    transform: translateY(0); /* Start at original position */
    opacity: 1;
  }
  50% {
    transform: translateY(10vh) rotate(15deg); /* Halfway down, with slight rotation */
    opacity: 1;
  }
  100% {
    transform: translateY(30vh) rotate(30deg); /* Drip to the floor with rotation */
    opacity: 1; /* Stay fully opaque */
  }
}

.drip-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.tooltip {
  position: absolute;
  top: 20%; /* Adjust tooltip positioning */
  left: 50%;
  transform: translate(-50%, -50%) rotate(20deg);
  background-color: #333;
  color: #fff;
  font-size: 0.8rem;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 1;
  display: inline-block;
}

.hide {
  opacity: 0;
}

.background-image-text {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-image: url('https://s2.svgbox.net/pen-brushes.svg?ic=brush-2&color=2d4059');
  background-size: cover;
  background-position: center;
  padding: 10px 20px;
  color: white;
  font-size: 3vw;
  font-weight: bold;
  border-radius: 8px;
  margin-top: 20px;
  width: 50%;
  animation: dripToFloorIamImportant 2s ease-in forwards;
  text-align: center;
}

@keyframes dripToFloorIamImportant {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(10vh) rotate(1deg);
    opacity: 1;
  }
  100% {
    transform: translateY(15vh) rotate(5deg);
    opacity: 1;
  }
}
