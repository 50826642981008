/* ProfileHeader.css */
@import "../../common.scss";
.profile-header {
  display: flex;
  align-items: center;
  padding: 40px;
  background-color: #f7f7f7;
  position: relative;
  border-radius: 10px;
}

.text-section {
  flex: 1;
  padding-right: 30px;
}

.text-section h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
  font-family: "Caveat", cursive;
  font-size: 6.2vw;
}

.text-section p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

.image-section {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image {
  width: 30em; /* Set a fixed width */
  height: 30em; /* Set a fixed height to match width */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image fills the circular shape properly */
  position: relative;
  z-index: 1;
  filter: grayscale(100%);
}


.shape {
  position: absolute;
  z-index: 0; /* Make sure shapes are behind the image */
  opacity: 0.8;
  mix-blend-mode: multiply;
}

.yellow-circle {
  width: 100px;
  height: 100px;
  background-color: #fbd24d;
  border-radius: 50%;
  top: 20%;
  left: -30px;
}

.pink-gradient {
  width: 150px;
  height: 150px;
  background: linear-gradient(45deg, #e54b4b, transparent);
  border-radius: 50%;
  bottom: 10%;
  left: -60px;
}

.yellow-square {
  width: 100px;
  height: 100px;
  background-color: #fbd24d;
  bottom: 0;
  right: -30px;
}

.yellow-circle1 {
  width: 100px;
  height: 100px;
  background-color: #fbd24d;
  border-radius: 50%;
  top: 50%;
  left: -50px;
}

.pink-gradient1 {
  width: 150px;
  height: 150px;
  background: linear-gradient(45deg, #e54b4b, transparent);
  border-radius: 50%;
  bottom: 20%;
  left: -130px;
}

.yellow-square1{
  width: 100px;
  height: 100px;
  background-color: #fbd24d;
  bottom: 0;
  left: 20%;
  right: -50px;
}