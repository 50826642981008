@import "../../common.scss";
@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@100..800&display=swap');

/* Stylish title CSS */
.stylish-title {
  font-family: "Pixelify Sans", sans-serif;
  font-optical-sizing: auto;
  font-size: 4rem;
  color: #000000;
  text-shadow: 10px 0px 0px $color-three;
  letter-spacing: 5px;
  text-align: center;
  margin: 20px 0;
}

.container {
  display: flex; /* Enables horizontal layout */
  align-items: flex-start; /* Align items at the start */
  justify-content: space-between; /* Adds space between items */
  width: 100%;
  padding: 20px;
}

.circle-video {
  width: 40vw;
  height: 40vw;
  max-width: 400px;
  max-height: 400px;
  border-radius: 50%;
  overflow: hidden;
  border: 8px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: animate-border 5s infinite ease-in-out;
}

/* Keyframes for animated border shapes */
@keyframes animate-border {
  0% {
    border-radius: 50%; /* Circle shape */
  }
  25% {
    border-radius: 40% 60% 60% 40% / 50% 40% 60% 50%; /* Elliptical shape */
  }
  50% {
    border-radius: 30% 70% 50% 50% / 60% 40% 60% 40%; /* Irregular shape */
  }
  75% {
    border-radius: 60% 40% 40% 60% / 40% 60% 40% 60%; /* Another irregular shape */
  }
  100% {
    border-radius: 50%; /* Back to the circle shape */
  }
}
.circle-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-top: 20px;
  margin-right: 20px; /* Adds some space between the camera and the smiley face */
}

.flat-button {
  margin-top: 10px;
  background-color: $secondary-color;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  transition: background-color 0.3s ease;
}

.flat-button:hover {
  background-color: $color-three;
  color: $primary-color;
}

.smiley-face-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  padding: 0; /* Remove padding */
  margin-left: 20px; /* Adds space between the camera and the smiley face */
  max-width: 500px; /* Sets the maximum width to a reasonable size */
  width: 100%; /* Ensures the container can expand to its max-width */
}

.detected-items {
  background-color: $color-three; /* Yellow background */
  margin: 20px 0; /* Add margin to space out the items vertically */
  padding: 10px; /* Padding inside the rectangle */
  border-radius: 5px;
  color: black; /* Black text color */
  font-family: "Playpen Sans", cursive;
  display: block; /* Ensure items are displayed in block format (new line) */
}

.detected-items-title {
  color: white;
  background-color: $primary-color;
  margin: 20px 0; /* Add margin to ensure space between title and items */
  padding: 10px;
  border-radius: 5px;
  display: block; /* Ensure it occupies its own line */
}
.not-detected-item{
  color: white;
  background-color: $secondary-color;
  margin: 20px 0; /* Add margin to ensure space between title and items */
  padding: 10px;
  border-radius: 5px;
  display: block; /* Ensure it occupies its own line */
}