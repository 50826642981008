@import url("https://fonts.googleapis.com/css2?family=Merriweather&family=Pacifico&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swapp");
@import "../common.scss";

body {
  margin: auto;
  font-family: "Roboto", sans-serif;
}
.content {
  position: static;
  width: 85%;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 50px;
  font-size: 18px;
  color: $primary-color;
}
